import { stripIndent } from 'common-tags';

const data = {
    title: 'Materials',
    seo: {
        title: 'Custom Jewellery and Grillz | Sydney | Illoom',
        description: 'illoom produces quality custom made grillz and fine jewellery. Based in Sydney, Australia. utilising gold, silver, platinum and diamonds to craft our piece. We can ship all pieces globally.'
    },
    items: [
        {
            title: 'Sterling Silver 995',
            text: stripIndent`
                <p>THESE ARE CRAFTED IN YOUR CHOICE OF 9K YELLOW, ROSE OR WHITE GOLD AND CUSTOM MADE TO FIT YOUR TEETH PERFECTLY. THESE ARE REMOVABLE GRILLZ, WE CAN ALSO PROVIDE A PERMANENT OPTION FOR OUR SYDNEY BASED CUSTOMERS.</p>
                <p>IF YOU HAVE ANY QUESTIONS AT ALL PLEASE SHOOT US AN EMAIL.</p>
                <p>TO ORDER, PLEASE LET US KNOW WHICH TEETH YOU WANT OFF OUR NUMBERED DIAGRAM IN THE IMAGES, WHICH METAL AND EITHER ORGANISE ONE OF OUR AT HOME MOULD KITS OR SEND A RECENT STUDY MODEL MADE BY YOUR DENTIST.BE AWARE THAT THE CUSTOMER IS RESPONSIBLE FOR SUPPLYING ACCURATE DENTAL MOULDS. WE SUPPLY OUR AT-HOME MOULD KITS, FULL INSTRUCTIONS AND SERVICE FOR THOSE WHO CANT COME INSTORE OR SEE A LOCAL DENTIST IF YOU FEEL UNABLE TO PROVIDE A MOULD USING THIS METHOD, PLEASE EITHER BOOK TO COME INSTORE OR SEE YOUR LOCAL DENTIST FOR YOUR IMPRESSIONS.</p>
                <p>YOU SHALL RECEIVE YOUR JOB BACK IN THE MAIL WITHIN ROUGHLY 1-4 WEEKS. ONCE RECEIVED CHECK FOR THE FIT BY SIMPLY PLACING AND PUSHING GENTLY OVER THE CHOSEN TOOTH. IT SHOULD WANT TO SIT IN PLACE. IF FOR SOME REASON THE FIT ISN’T PERFECT PLEASE SEND BACK AND WE WILL READJUST AND OR ASK FOR A SECONDARY MOULD IF THE READJUSTMENT IS UNSUCCESSFUL. THIS IS SOMETIMES NECESSARY FOR CUSTOM WORK THAT REQUIRES A PRECISE FIT. WE WILL SEND YOU A SECONDARY MOULD KIT FOR YOU TO TAKE AND SEND BACK WITH THE TOOTH. PLEASE UNDERSTAND CUSTOM MADE PRODUCTS DO TAKE TIME AND OCCASIONALLY REQUIRE SOME BACK AND FORTH TO GET YOU YOUR IDEAL PIECE AND PERFECT FIT.</p>
            `,
            image: require('../../src/assets/images/global/9.jpg'),
        },
        {
            title: 'Sterling Silver 995',
            text: stripIndent`
                <p>THESE ARE CRAFTED IN YOUR CHOICE OF 9K YELLOW, ROSE OR WHITE GOLD AND CUSTOM MADE TO FIT YOUR TEETH PERFECTLY. THESE ARE REMOVABLE GRILLZ, WE CAN ALSO PROVIDE A PERMANENT OPTION FOR OUR SYDNEY BASED CUSTOMERS.</p>
                <p>IF YOU HAVE ANY QUESTIONS AT ALL PLEASE SHOOT US AN EMAIL.</p>
                <p>TO ORDER, PLEASE LET US KNOW WHICH TEETH YOU WANT OFF OUR NUMBERED DIAGRAM IN THE IMAGES, WHICH METAL AND EITHER ORGANISE ONE OF OUR AT HOME MOULD KITS OR SEND A RECENT STUDY MODEL MADE BY YOUR DENTIST.BE AWARE THAT THE CUSTOMER IS RESPONSIBLE FOR SUPPLYING ACCURATE DENTAL MOULDS. WE SUPPLY OUR AT-HOME MOULD KITS, FULL INSTRUCTIONS AND SERVICE FOR THOSE WHO CANT COME INSTORE OR SEE A LOCAL DENTIST IF YOU FEEL UNABLE TO PROVIDE A MOULD USING THIS METHOD, PLEASE EITHER BOOK TO COME INSTORE OR SEE YOUR LOCAL DENTIST FOR YOUR IMPRESSIONS.</p>
                <p>YOU SHALL RECEIVE YOUR JOB BACK IN THE MAIL WITHIN ROUGHLY 1-4 WEEKS. ONCE RECEIVED CHECK FOR THE FIT BY SIMPLY PLACING AND PUSHING GENTLY OVER THE CHOSEN TOOTH. IT SHOULD WANT TO SIT IN PLACE. IF FOR SOME REASON THE FIT ISN’T PERFECT PLEASE SEND BACK AND WE WILL READJUST AND OR ASK FOR A SECONDARY MOULD IF THE READJUSTMENT IS UNSUCCESSFUL. THIS IS SOMETIMES NECESSARY FOR CUSTOM WORK THAT REQUIRES A PRECISE FIT. WE WILL SEND YOU A SECONDARY MOULD KIT FOR YOU TO TAKE AND SEND BACK WITH THE TOOTH. PLEASE UNDERSTAND CUSTOM MADE PRODUCTS DO TAKE TIME AND OCCASIONALLY REQUIRE SOME BACK AND FORTH TO GET YOU YOUR IDEAL PIECE AND PERFECT FIT.</p>
            `,
            image: require('../../src/assets/images/global/15.jpg'),
        }
    ]
}

export default data