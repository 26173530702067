import React, { useContext, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { MetaTags, Header } from '../'
import { media, useBreakpoint } from '../../styles/utils'
import { container } from '../../styles/global'

import { IsMobileContext } from '../../context/IsMobileContext'

const Layout = ({ children, meta, headerOptions, isHome, cartLink }) => {
    const isMobile = useContext(IsMobileContext)
    const detectMob = useBreakpoint('mob')

    useMount(() => {
		isMobile.setIsMobile(detectMob)
    })

    useEffect(() => {
        isMobile.setIsMobile(detectMob)
    }, [detectMob])
    
    return (
        <>
            {typeof isHome !== 'undefined' && (
                <HomeStyles/>
            )}
            <MetaTags {...meta} />
            <Transition>
                <Header template={headerOptions?.template} images={headerOptions?.images} cartLink={cartLink} useMobileSlider={headerOptions?.useMobileSlider} />
                {children}
            </Transition>
        </>
    )
}


const Wrapper = styled.div``
const HomeStyles = createGlobalStyle`
    body, html {
        position: fixed;
        overflow: hidden;
        &, * {
            overscroll-behavior: none;
            -webkit-overflow-scrolling: initial;
        }
    }
`

export default Layout
