import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Div100vh from 'react-div-100vh'

import { container, padding, bgImage } from '../../styles/global'
import { media } from '../../styles/utils'

function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    )
  }

const MobileSlider = props => {
    const { slides } = props
    const [currentSlide, setCurrentSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider({
        loop: true,
        initial: 0,
        slideChanged(slider) {
          setCurrentSlide(slider.track.details.rel)
        },
        created() {
          setLoaded(true)
        },
    })

    const renderSlides = () => {
        const items = slides.map((item, i) => {
            const image = item?.image
            return (
                <Slide
                    key={i}
                    className="keen-slider__slide"
                >
                    {image && (
                        <BGImage
                            image={image}
                        />
                    )}
                </Slide>
            )
        })

        return items
    }

    const renderLogo = () => {
        return (
            <Logo
                onClick={() => navigate(`/`)}
            >
                <LoadedImage
                    src={require('../../assets/images/logos/illoom-logo-mobile.svg')}
                />
            </Logo>
        )
    }

    const renderArrows = () => {
        return (
            <>
                  <Arrow
                    left
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                  />
      
                  <Arrow
                    onClick={(e) =>
                      e.stopPropagation() || instanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      instanceRef.current.track.details.slides.length - 1
                    }
                  />
            </>
        )
    }

    return (
        <Wrapper>
            {slides && (
                <div
                    ref={sliderRef}
                    className={'keen-slider'}
                >
                    {renderSlides()}
                </div>
            )}
            {loaded && instanceRef.current && renderArrows()}
            {renderLogo()}
        </Wrapper>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img``

// Layout

const Wrapper = styled(Div100vh)`
    position: relative;
    width: 100%;
    overflow: hidden;
    display: none;

    @media(max-width: 1024px) {
        display: block;
    }

    .keen-slider {
        height: 100%;
    }

    .arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        fill: #C9C9C9;
        cursor: pointer;
    }

    .arrow--left {
        left: 5px;
    }

    .arrow--right {
        left: auto;
        right: 5px;
    }
`

const Logo = styled.div`
    z-index: 11;
    position: absolute;
    top: 5px;
    left: initial;
    width: 100%;
    padding: 0 1rem;
    ${container}
    padding: 0 1rem;
    height: auto;
`

const Slide = styled.div`
    width: 100%;
    height: 100%;

    ${BGImage} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`

export default MobileSlider
