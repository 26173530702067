import { stripIndent } from 'common-tags';

const data = {
    title: 'Mould Kits',
    seo: {
        title: 'Custom Jewellery and Grillz | Sydney | Illoom',
        description: 'illoom produces quality custom made grillz and fine jewellery. Based in Sydney, Australia. utilising gold, silver, platinum and diamonds to craft our piece. We can ship all pieces globally.'
    },
    headerOptions: {
        template: 'default',
        images: [
            {
                image: require('../../src/assets/images/mould-kits/9.jpg')
            },
            {
                image: require('../../src/assets/images/mould-kits/38.jpg')
            }
        ]
    },
    sidebarContent: {
        text: stripIndent`
            <p>Be aware that the customer is responsible for supplying accurate dental moulds. We supply our at-home mould kits, full instructions and service for those who cant come instore or see a local dentist if you feel unable to provide a mould using this method, please either book to come instore or see your local dentist for your impressions.</p>
            <p>Make sure your mould has clearly defined teeth, with no smudges or tears in the silicone. Please reference the below images for examples.</p>
            <p>Make sure the silicone is firmly in place in the dental tray. If there are any issues or questions, please send us an email.</p>
            <p>If everything seems correct, please rinse the mould under cold water and package securely.</p>
            <p>SEND TO:<br />
            ILLOOM<br />
            PO BOX 69<br />
            ERSKINEVILLE<br />
            NSW 2043<br />
            AUSTRALIA</p>
        `,
    },
    mainContent: {
        text: stripIndent`
            <p>Currently, FREE with the purchase of all Grillz orders. To allow us to be able to continue our work and for you to avoid travel and unnecessary contact. Please select the (with purchase kits) along with your Grillz order to access the free kits, or purchase the regular kits by themselves.</p>
        `,
        links: stripIndent`
            <a href="/shop/mould-kits">Order Mould Kit</a><br />
            <a href="https://youtube.com" target="_blank">Watch our how to video</a>
        `
    }
}

export default data