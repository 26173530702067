import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import { MobileMenu, Slider } from '../'
import { MobileMenuContext } from '../MobileMenu/context'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { renderLink } from '../../utils'
import { cartContext } from '../Cart/CartProvider'

const Header = props => {
    const { template, images, cartLink, useMobileSlider } = props
    const mobileMenu = useContext(MobileMenuContext)
    const { cartItems } = useContext(cartContext)

    const menu = [
        {
            link: {
                url: template === 'home' ? '/archive' : '/',
                title: template === 'home' ? 'Archive' : 'Illoom',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/mould-kits',
                title: 'Mould Kits',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/materials',
                title: 'Materials',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/shop',
                title: 'Shop',
                type: 'internal'
            }
        },
    ]

    const renderMenuItems = menu => {
        if (!menu) return
        return menu.map((item, i) => {
            const link = item?.link
            if (!link) return
            return (
                <Item key={i}>
                    {renderLink(link, i)}
                </Item>
            )
        })
    }

    let cartLabel = 'Cart'
    let numCartItems = 0
    if (cartItems.length) {
        cartItems.forEach(item => {
            numCartItems = numCartItems + (item.quantity)
        })
        cartLabel = `Cart (${numCartItems})`
    }

    return (
        <>
            {/* Mobile Menu */}
            <AnimatePresence>
                {mobileMenu.active && (
                    <MobileMenuWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MobileMenu
                            template={template}
                        />
                    </MobileMenuWrapper>
                )}
            </AnimatePresence>

            <Wrapper sliderActive={images}>
                <Container>
                    <Nav>
                        {renderMenuItems(menu)}
                        {!cartLink && (
                            renderMenuItems([
                                {
                                    link: {
                                        url: 'mailto:info@illoom.com.au',
                                        title: 'Contact',
                                        type: 'external'
                                    }
                                },
                            ])
                        )}
                        {cartLink && (
                            renderMenuItems([
                                {
                                    link: {
                                        url: '/cart',
                                        title: cartLabel,
                                        type: 'internal'
                                    }
                                },
                            ])
                        )}
                    </Nav>
                    {/* Tablet Only */}
                    <MobileNav>
                        <MobileNavToggle
                            onClick={() => {
                                mobileMenu.toggleActive()
                            }}
                        >
                            Menu
                        </MobileNavToggle>
                        {
                            renderMenuItems([
                                {
                                    link: {
                                        url: '/cart',
                                        title: cartLabel,
                                        type: 'internal'
                                    }
                                },
                            ])
                        }
                    </MobileNav>
                </Container>
            </Wrapper>
            {images && (
                <Slider slides={images} template={template} useMobileSlider={useMobileSlider} />
            )}
        </>
    )
}

// Layout

const Wrapper = styled.header`
    ${tw`z-20 fixed bottom-0 inset-x-0 w-full`};

    /* ${props => {
        if (props.sliderActive)
            return css`
                ${tw`text-white`};
            `
    }} */
`

const Container = styled.div`
    ${container}
    padding: 0 1rem;
`

// Menu

const menuLink = css`
    ${type.headerLinks};
    ${tw`cursor-pointer`};
    /* ${hoverState}; */
`

const Item = styled.div``
const MenuItem = styled.a``
const Nav = styled.div`
    ${tw`flex justify-between w-full`};
    padding: 2px 0;

    ${media.tab`
        display: none;
    `}

    .link, a {
        ${menuLink};
    }
`

const MobileNav = styled.div`
    ${tw`flex justify-between w-full`};
    padding: 2px 0;

    display: none;
    ${media.tab`
        display: flex;
    `}

    ${Item} {
        ${tw`w-1/2`};

        > * {
            ${menuLink};
        }
    }

    > *:first-child {
        ${tw`text-left`};
    }

    > *:nth-child(2) {
        ${tw`text-right`};
    }
`

// Mobile Nav Toggle

const MobileNavToggle = styled.div`
    display: none;
    ${type.headerLinks};
    ${tw`text-grey`};

    ${media.tab`
        ${tw`block`};
    `}
`

// Mobile Menu

const MobileMenuWrapper = styled(motion.div)`
    ${tw`z-30 fixed inset-0 w-full h-full bg-white`};
    overflow: hidden;
`

export default Header
