import React, { useState, useEffect, useContext } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import { find, filter, forEach, isEqual, remove } from 'lodash'
import { useMount } from 'react-use'
import Select from 'react-select'
import NumberFormat from 'react-number-format'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import { container, padding, hoverState, bgIcon, button, type } from '../../styles/global'
import { filterVariantsByOption } from './utils'
import { getGlobalData } from '../../utils'
import { cartContext } from '../Cart/CartProvider'
import { getNumberScaleProps } from '../../utils/shopify'

const ProductForm = (props) => {
    const { productData, productType } = props
    if (!productData) return

    const {
        cartItems,
        setCartItems,
        addCartItem,
        active: cartActive,
        setActive: setCartActive,
    } = useContext(cartContext)

    const [selectedOptions, setSelectedOptions] = useState({})
    const [availableVariants, setAvailableVariants] = useState({})
    const [selectedVariant, setSelectedVariant] = useState(false)
    const [orderNote, setOrderNote] = useState('')
    const variants = productData?.variants
    const options = productData?.options
    // console.log(selectedVariant)
    
    useEffect(() => {
        // set selected variant
        if (availableVariants.length === 1) {
            setSelectedVariant({
                ...productData,
                selectedVariant: {
                    ...availableVariants[0],
                    image: {
                        originalSrc: availableVariants?.[0]?.image?.originalSrc || productData?.featuredImage?.originalSrc
                    }
                },
            })
        } else {
            setSelectedVariant(false)
        }
    }, [availableVariants])

    useEffect(() => {
        let matches = variants

        forEach(selectedOptions, (value, key) => {
            matches = filter(matches, (variant) => {
                const matchOption = find(variant.selectedOptions, {
                    name: key,
                    value: value,
                })

                if (matchOption) return variant
            })
        })

        // only one available variant, so set the selected options to match that
        if (matches.length === 1) {
            let theseOptions = {}
            forEach(matches[0]?.selectedOptions, (option, key) => {
                theseOptions[option.name] = option.value
            })

            if (!isEqual(selectedOptions, theseOptions)) {
                setSelectedOptions({
                    ...theseOptions,
                })
            }
        }

        setAvailableVariants(matches)
    }, [selectedOptions])

    const checkDisabled = (option) => {
        // Filter variants by passed option
        const matches = filterVariantsByOption(availableVariants, option)

        if (!matches.length) return true
    }

    const checkSelected = (option) => {
        let match = false

        for (const key in selectedOptions) {
            if (selectedOptions.hasOwnProperty(key)) {
                if (
                    key === option.name &&
                    selectedOptions[key] === option.value
                ) {
                    match = true
                }
            }
        }

        return match
    }

    const handleSelectChange = (optionName, value) => {
        const option = {
            name: optionName,
            value: value,
        }

        setSelectedOptions({
            ...selectedOptions,
            [option.name]: option.value,
        })
    }

    const resetForm = () => {
        setSelectedOptions({})
        setOrderNote('')
    }

    const renderOptions = () => {
        const items = options.map((option, i) => {
            let selectOptions = []
            let selectedOption;
            // console.log(options)

            option.values.forEach((option) => {
                selectOptions.push({
                    value: option,
                    label: option,
                })
            })
            
            if (selectedOptions[option.name]) {
                selectedOption = {
                    value: selectedOptions[option.name],
                    label: selectedOptions[option.name],
                }
            } else {
                selectedOption = false
            }

            if (selectedOptions[option.name] == 'Default Title') return

            return (
                <>
                    <Heading>{option?.name}</Heading>
                    <Select
                        key={i}
                        options={selectOptions}
                        value={selectedOption}
                        onChange={(item) =>
                            handleSelectChange(option.name, item.value)
                        }
                        isSearchable={false}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                primary: 'white',
                                primary25: 'white',
                                primary50: 'white',
                                primary75: 'white',
                            },
                        })}
                    />
                </>
            )
        })

        return <Options>{items}</Options>
    }

    const renderPrice = () => {
        let price

        if (!selectedVariant) {
            price = variants[0]?.price
        } else {
            price = selectedVariant?.selectedVariant?.price
        }

        return (
            <Price>
                <NumberFormat
                    value={price}
                    prefix={'$'} 
                    displayType={'text'} 
                    {...getNumberScaleProps()}
                />
            </Price>
        )
    }

    const handleNoteChange = e => {
        setOrderNote(e.target.value)
    }

    const renderNotes = () => {
        return (
            <OrderNotes>
                <Heading>Notes</Heading>
                <textarea
                    rows={'4'}
                    value={orderNote}
                    onChange={handleNoteChange}
                />
            </OrderNotes>
        )
    }

    const renderAddToCart = () => {
        return (
            <AddToCart
                disabled={!selectedVariant}
            >
                <Button
                    style={{
                        opacity: !selectedVariant?.selectedVariant?.availableForSale ? '0.5' : '1',
                        pointerEvents: !selectedVariant?.selectedVariant?.availableForSale ? 'none' : 'initial'
                    }}
                    className={'button add-to-cart'}
                    onClick={() => {
                        if (selectedVariant?.selectedVariant?.availableForSale) {
                            addCartItem(selectedVariant, orderNote)
                            resetForm()
                        }
                    }}
                >
                    Add to Cart
                </Button>
                {selectedVariant && !selectedVariant?.selectedVariant?.availableForSale && (
                    <Description
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        Sorry, this item is sold out
                    </Description>
                )}
            </AddToCart>
        )
    }

    const renderAddons = () => {
        if (productType !== 'grillz' || productData?.handle == 'custom-grill-additions') return

        return (
            <Addons>
                <Description>
                    After adding grillz to your cart, you can buy add-ons from the <Link to={'/shop/custom-grill-additions'}>Custom Grill Add-ons</Link> page
                </Description>
            </Addons>
        )
    }

    return (
        <>
            {renderPrice()}
            {options && renderOptions()}
            {renderNotes()}
            {renderAddToCart()}
            {renderAddons()}
        </>
    )
}

// Shared

const Heading = styled.h2``
const Title = styled.div``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Container = styled.div``

const Wrapper = styled.div``

// Options

const Options = styled.div`
    margin-top: 6rem;

    ${media.tabPor`
        margin-top: 1rem;
    `}

    ${Heading} {
        ${type.subheading}
    }

    ${Heading}:not(:first-child) {
        margin-top: 1.5rem;

        ${media.tabPor`
            margin-top: 1rem;
        `}
    }

    > .react-select-container {
        margin-top: 0.5rem;

        &, * {
            ${type.subheading}
        }

        &, .react-select__control {
            height: 42px;
        }
        
        .react-select__control {
            ${tw`border border-grey rounded-none`};
            border-color: #C9C9C9!important;
        }

        .react-select__menu {
            ${tw`bg-white border border-grey rounded-none`};
            box-shadow: none;
        }

        .react-select__indicator {
            display: none;
        }

        .react-select__menu-list {
            margin: 0;
            padding: 0;
        }

        .react-select__option {
            min-height: 42px;
            display: flex;
            align-items: center;
            margin: 0;
            cursor: pointer;

            &:not(:last-child) {
                border-bottom: 1px solid #C9C9C9;
            }
        }
    }
`

// Addons (grillz)

const Addons = styled.div`
    margin-top: 1.5rem;

    ${media.tabPor`
        margin-top: 1rem;
    `}

    ${Description} {
        a {
            text-decoration: underline;
        }
    }
`

// Order Notes

const OrderNotes = styled.div`
    ${Heading} {
        ${type.subheading}

        margin-top: 1.5rem;

        ${media.tabPor`
            margin-top: 1rem;
        `}
    }

    textarea {
        width: 100%;
        resize: none;
        margin-top: 0.5rem;
        padding: 8px;
        ${tw`border border-grey rounded-none`};
        border-color: #C9C9C9!important;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;
        ${type.body}

        &,
        &::placeholder {
            ${tw`text-grey`};
            opacity: 1;
        }

        &:focus {
            outline: none;
        }
    }
`

// Price

const Price = styled.div`
    margin-top: 1rem;
    &, * {
        ${type.heading}
    }
`

// Buttons (Add to Cart)

const Icon = styled.img``
const Button = styled.div``
const AddToCart = styled.div`
    width: 100%;
    margin-top: 4rem;

    ${Button} {
        ${type.heading}
        cursor: pointer;

        ${props => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                    opacity: 0.5;
                `
        }}
    }
`

export default ProductForm
