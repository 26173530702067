const global_data = {
    global_images: [
        {
            image: require('../src/assets/images/global/1.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/2.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/3.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
            size: 'contain',
            orientation: 'portrait',
        },
        {
            image: require('../src/assets/images/global/4.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
            size: 'contain'
        },
        {
            image: require('../src/assets/images/global/5.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
            size: 'contain',
            orientation: 'portrait',
        },
        {
            image: require('../src/assets/images/global/6.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/7.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/8.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/9.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
            size: 'contain'
        },
        {
            image: require('../src/assets/images/global/10.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/11.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/12.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/13.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/14.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
            size: 'contain',
            orientation: 'portrait',
        },
        {
            image: require('../src/assets/images/global/15.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
        {
            image: require('../src/assets/images/global/16.jpg'),
            caption: 'Diamond Ring VVS 24 White Gold',
        },
    ]
}

export default global_data