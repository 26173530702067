import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { container, padding } from '../../styles/global'
import { media } from '../../styles/utils'
import { motion } from 'framer-motion'
import tw from 'tailwind.macro'
import { useToggle } from 'react-use'

const Accordion = props => {
    const { heading, text } = props
    const [active, toggle] = useToggle(false)

    const collapse = {
        hidden: {
            height: 0,
            opacity: 0
        },
        visible: {
            height: 'initial',
            opacity: 1
        },
    }

    return (
        <Wrapper>
            <Heading
                onClick={() => toggle()}
            >
                <span>{heading}</span>
                <span>{active ? '-' : '+'}</span>
            </Heading>

            <Content
                initial={'hidden'}
                animate={active ? 'visible' : 'hidden'}
                variants={collapse}
                transition={{
                    ease: 'linear'
                }}
            >
                <Description
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            </Content>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Description = styled.div``
const Content = styled(motion.div)``

const Wrapper = styled.div`
    ${tw`w-full border-t border-b border-grey`};

    ${Heading} {
        ${tw`flex justify-between items-center`};
        user-select: none;
        cursor: pointer;
        padding: 1rem 0;
    }

    ${Content} {
        overflow: hidden;
    }

    ${Description} {
        > * {
            margin-bottom: 1em;
        }
    }
`

export default Accordion
