import React, { useRef, useState, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import useScratch from 'react-use/lib/useScratch'
import useDimensions from "react-use-dimensions"
import Div100vh from 'react-div-100vh'

import { container, padding, bgImage } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'

import { IsMobileContext } from '../../context/IsMobileContext'

import 'swiper/swiper-bundle.css'

const HomeSlideNavItem = ({index, image, toSlide, currentX, currentY, currentSlide}) => {
    const [ref, {left, top, x, y, width, height}] = useDimensions()
    const { isMobile } = useContext(IsMobileContext)

    useEffect(() => {
        if (currentX > x
            && currentX < (x + width)
            && currentY > y
            && currentY < (y + height)
            && currentSlide !== index) {
            toSlide(index)
        }
    }, [currentX, currentY])

    return (
        <Item
            ref={ref}
            onMouseEnter={() => {
                !isMobile && image && toSlide(index)
            }}
        />
    )
}

const Slider = props => {
    const { slides, template, useMobileSlider } = props
    const swiperRef = useRef(null)
    const [currentSlide, setCurrentSlide] = useState(false)
    const { isMobile } = useContext(IsMobileContext)

    const [ref, state] = useScratch();
    let { dx = 0, dy = 0, docX, docY } = state;
    const [currentY, setCurrentY] = useState(false)
    const [currentX, setCurrentX] = useState(false)
    // console.log(currentSlide)

    useEffect(() => {
        if (currentX !== docX+dx) {
            setCurrentX(docX+dx)
        }
    }, [dx])

    useEffect(() => {
        if (currentY !== docY+dy) {
            setCurrentY(docY+dy)
        }
    }, [dy])

    useEffect(() => {
        if (swiperRef.current) {
            setCurrentSlide(swiperRef.current?.swiper?.activeIndex)
        }
    }, [swiperRef?.current?.swiper?.activeIndex])

    const params = {
        effect: false,
        speed: 0,
        noSwiping: true,
    }

    const nextSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper?.slideNext()
        }
    }

    const prevSlide = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper?.slidePrev()
        }
    }

    const toSlide = index => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper?.slideTo(index)
        }
    }

    const renderSlidesHome = () => {
        if (!slides) return

        if (slides.length < 16) {
            let j = 0
            for (let i = 0; i < 16; i++) {
                if (typeof slides[i] === 'undefined') {
                    slides[i] = {}
                    j++
                }
            }
        }

        const items = slides.map((item, i) => {
            let imageRewrite
            if (item?.image?.sizes?.medium2) {
                imageRewrite = item?.image?.sizes?.medium2.replace('https://admin.illoom.com.au','')
            }
            if (i+1 > 16) return
            const image = imageRewrite || item?.image?.sizes?.medium2
            return (
                <Slide key={i}>
                    {image && (
                        <BGImage
                            image={image}
                            size={!item.full_screen ? 'contain' : 'false'}
                        />
                    )}
                </Slide>
            )
        })

        return items
    }

    const renderSlideNavHome = () => {
        return slides.map((item, i) => {
            return (
                <HomeSlideNavItem
                    key={i}
                    index={i}
                    image={item?.image}
                    toSlide={toSlide}
                    currentX={currentX}
                    currentY={currentY}
                    currentSlide={currentSlide}
                />
            )
        })
    }

    const renderSlides = () => {
        if (!slides) return

        return slides.map((item, i) => {
            let imageRewrite
            if (item?.image?.sizes?.medium2) {
                imageRewrite = item?.image?.sizes?.medium2.replace('https://admin.illoom.com.au','')
            }
            const image = imageRewrite || item?.image
            return (
                <Slide key={i}>
                    {image && (
                        <BGImage
                            image={image}
                        />
                    )}
                </Slide>
            )
        })
    }

    const renderSlideNav = () => {
        return slides.map((item, i) => {
            return (
                <Item
                    key={i}
                    onMouseEnter={() => item.image && toSlide(i)}
                />
            )
        })
    }

    const renderLogo = isHome => {
        return (
            <Logo
                isHome={isHome}
                onClick={() => navigate(`/`)}
            >
                <Image
                    key={'illoom-logo'}
                    src={isMobile ? require('../../assets/images/logos/illoom-logo-mobile.svg') : require('../../assets/images/logos/illoom-logo.svg')}
                >
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src}
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image> 
            </Logo>
        )
    }

    return (
        <Wrapper
            useMobileSlider={useMobileSlider}
        >
            {template === 'home' && (
                <>
                    <SlideNav template={template} ref={ref}>
                        {renderSlideNavHome()}
                    </SlideNav>
                    <Swiper {...params} ref={swiperRef}>
                        {renderSlidesHome()}
                    </Swiper>
                    {renderLogo(true)}
                </>
            )}
            {template !== 'home' && (
                <>
                    <SlideNav template={template} items={slides.length}>
                        {renderSlideNav()}
                    </SlideNav>
                    <Swiper {...params} ref={swiperRef}>
                        {renderSlides()}
                    </Swiper>
                    {renderLogo(false)}
                </>
            )}
        </Wrapper>
    )
}

// Shared

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;

    ${props => {
        if (props.size === 'contain')
            return css`
                background-size: auto;

                ${media.mob`
                    background-size: 70% auto;
                `}
            `
    }}
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled(Div100vh)`
    ${tw`relative w-full`};
    overflow: hidden;

    ${props => {
        if (props.useMobileSlider)
            return css`
                @media(max-width: 1024px) {
                    display: none;
                }
            `
    }}

    /* custom swiper styles */
    .swiper-container {
        ${tw`w-full h-full`};
    }
`

const Logo = styled.div`
    ${container}
    padding: 0 1rem;
    height: auto;
    ${tw`absolute top-0`};
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
    display: none;

    ${props => {
        if (props.isHome)
            return css`
                display: block;
                pointer-events: none;
            `
    }}

    ${media.mob`
        display: block!important;
    `}

    ${media.mob`
        top: 5px;
        left: initial;
        transform: none;
        width: 100%;
        padding: 0 1rem;
    `}
`

const Slide = styled.div`
    ${tw`w-full h-full`};
    ${BGImage} {
        ${tw`absolute inset-0 w-full h-full`};
    }
`

// Slide Nav

const Item = styled.div``
const SlideNav = styled.div`
    ${tw`absolute inset-0 flex flex-wrap w-full h-full`};
    z-index: 10;
    user-select: none;
    overscroll-behavior: none;

    /* > * {
        ${tw`border border-black`};
    } */

    ${props => {
        if (props.template === 'home')
            return css`
                > *:first-child,
                > *:nth-child(8),
                > *:nth-child(9),
                > *:nth-child(16) {
                    width: 100%;
                    height: 10%;
                }

                > *:nth-child(2),
                > *:nth-child(3),
                > *:nth-child(4),
                > *:nth-child(5),
                > *:nth-child(6),
                > *:nth-child(7),
                > *:nth-child(10),
                > *:nth-child(11),
                > *:nth-child(12),
                > *:nth-child(13),
                > *:nth-child(14),
                > *:nth-child(15) {
                    height: 30%;
                }

                
                > *:nth-child(2),
                > *:nth-child(3),
                > *:nth-child(6),
                > *:nth-child(7),
                > *:nth-child(10),
                > *:nth-child(11),
                > *:nth-child(14),
                > *:nth-child(15) {
                    ${tw`w-1/12`};
                }

                > *:nth-child(4),
                > *:nth-child(5),
                > *:nth-child(12),
                > *:nth-child(13) {
                    ${tw`w-1/3`};
                }
            `
    }}

    ${props => {
        if (props.template !== 'home')
            return css`
                > * {
                    width: ${1/props.items*100}%;
                    height: 100%;
                }
            `
    }}
`

export default Slider
