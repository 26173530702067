import global_data from '../global'

const data = {
    title: 'Home',
    seo: {
        title: 'Custom Jewellery and Grillz | Sydney | Illoom',
        description: 'illoom produces quality custom made grillz and fine jewellery. Based in Sydney, Australia. utilising gold, silver, platinum and diamonds to craft our piece. We can ship all pieces globally.'
    },
    headerOptions: {
        template: 'home',
        images: [
            ...global_data.global_images
        ]
    }
}

export default data