import React, { useState, useRef, useContext } from 'react'
import { navigate } from 'gatsby'
import { useMount, useUnmount } from 'react-use'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { container, padding, type } from '../../styles/global'
import { navigation } from '../../styles/type'

import { MobileMenuContext } from '../MobileMenu/context'

const MobileMenu = props => {
    const { template } = props
    const mobileMenuEl = useRef(null)
    const mobileMenu = useContext(MobileMenuContext)

    const menu = [
        {
            link: {
                url: template === 'home' ? '/archive' : '/',
                title: template === 'home' ? 'Archive' : 'Illoom',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/mould-kits',
                title: 'Mould Kits',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/materials',
                title: 'Materials',
                type: 'internal'
            }
        },
        {
            link: {
                url: '/shop',
                title: 'Shop',
                type: 'internal'
            }
        },
        {
            link: {
                url: 'mailto:info@illoom.com.au',
                title: 'Contact',
                type: 'external'
            }
        },
    ]

    console.log(menu)

    useMount(() => disableBodyScroll(mobileMenuEl.current))

    useUnmount(() => clearAllBodyScrollLocks())

    const renderMenu = () => {
        if (!menu) return
        return menu.map((item, i) => {
            const link = item?.link
            if (!link) return
            if (link.type === 'external') {
                return (
                    <ExternalLink
                        key={i}
                        href={link?.url}
                        target={'_blank'}
                    >
                        {link?.title}
                    </ExternalLink>
                )
            }
            return (
                <MenuItem
                    key={i}
                    onClick={() => {
                        navigate(link?.url)
                        mobileMenu.toggleActive()
                    }}
                >
                    {link?.title}
                </MenuItem>
            )
        })
    }

    return (
        <Wrapper ref={mobileMenuEl}>
            <Container>
                <Menu>
                    <ContactLink
                        href={'mailto:info@illoom.com.au'}
                        target={'_blank'}
                    >
                        info@illoom.com.au
                    </ContactLink>
                    {renderMenu()}
                    <CloseToggle
                        onClick={() => mobileMenu.toggleActive()}
                    >
                        Close
                    </CloseToggle>
                </Menu>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`w-full h-full`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const ContactLink = styled.a``
const ExternalLink = styled.a``
const MenuItem = styled.div``
const CloseToggle = styled.div``
const Menu = styled.div`
    ${tw`flex flex-col justify-end h-full`};
    padding-bottom: 2px;

    ${ContactLink} {
        ${type.headerLinks}
        margin-bottom: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${ExternalLink}, ${MenuItem} {
        ${type.headerLinks}
    }

    ${CloseToggle} {
        margin-top: 4rem;
        ${type.headerLinks}
    }
`

export default MobileMenu
