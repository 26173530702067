import React, { useState, useContext, Component, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useLocation, useMount, useUnmount, useSetState } from 'react-use'
import Form from 'react-dynamic-form'
import { required, email, phone } from '../../utils/validators'
import tw from 'tailwind.macro'

import { media } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'

const ContactForm = props => {
    const { heading, cta } = props
    // console.log(props)
    const signupFormRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [error, setError] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        const { formComplete } = fields
        console.log(signupFormRef.current)

        setSubmitting(true)

        // make api call
        
        setSubmitting(false)
        setFormComplete(true)
    }

    return (
        <Wrapper
            formComplete={formComplete}
        >
            <Heading className={`form-heading`}>
                {heading && heading}
            </Heading>

            {formComplete && (
                <Description className={'form-thanks'}
                    dangerouslySetInnerHTML={{
                        __html: `Thanks for your enquiry.<br /> We'll get back to within 4 business hours.`
                    }}
                >
                </Description>
            )}

            <FormWrapper
                className={'form-wrapper'}
                formComplete={formComplete}
            >
                <Form
                    ref={signupFormRef}
                    data={signupFields}
                    moveToInvalidField={false}
                    renderSubmit={false}
                    onSubmit={(fields, resetForm) =>
                        handleSubmit(fields, resetForm)
                    }
                />

                {error && (
                    <Error
                        className={'form-error'}
                        dangerouslySetInnerHTML={{
                            __html: error,
                        }}
                    />
                )}

                <Submit>
                    <button
                        disabled={submitting ? true : false}
                        onClick={() => {
                            signupFormRef.current &&
                                signupFormRef.current.handleSubmit()
                        }}
                    >
                        Submit
                    </button>
                    {cta && (
                        <Info className={'form-cta'}>{cta}</Info>
                    )}
                </Submit>
            </FormWrapper>
        </Wrapper>
    )
}

const Heading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Error = styled.div``
const Submit = styled.div``

const Wrapper = styled.div`
    ${tw`relative flex flex-col items-start`};

    ${Heading}, ${Description} {
        ${tw`font-body text-black`};
    }

    ${Heading} {
        ${props => {
            if (props.formComplete)
                return css`
                    opacity: 0;
                `
        }}
    }

    .form-thanks {
        ${tw`absolute top-0 inset-x-0 w-full`};
    }

    ${Error} {
        ${tw`font-body text-black`};
        font-size: 1rem;
        margin-top: 1rem;
        color: rgb(203, 0, 0);
    }
`

const FormWrapper = styled.div`
    ${tw`flex flex-col items-start`};

    ${props => {
        if (props.formComplete)
            return css`
                opacity: 0;
                &, * {
                    pointer-events: none;
                }
            `
    }}

    &,
    > div {
        width: 100%;
    }

    .dynamic-field {
        /* error message */
        > *:nth-child(2) {
            margin-top: 0.5rem;
            font-size: 1rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        input, textarea {
            padding: 1rem 0 1rem;
        }
    }

    input, textarea {
        ${tw`border-b border-black`};
        width: 100%;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        background: transparent;

        &,
        &::placeholder {
            ${tw`text-black`};
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        ${(props) => {
            if (props.disabled)
                return css`
                    pointer-events: none;
                `
        }}
    }

    textarea {
        resize: none;
    }

    ${Submit} {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 2.5rem;

        button {
            margin-right: 2rem;
            appearance: none;
            box-shadow: none;
            background-color: transparent;
            ${button}
            ${tw`border-black text-black`};

            &:focus {
                outline: none;
            }
        }

        ${Info} {
            ${tw`font-body`};
            font-size: 1rem;
        }
    }
`

class Input extends Component {
    onChange = (e) => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderInput = () => {
        const { type } = this.props
        if (type === 'textarea') {
            return (
                <textarea rows={'6'} onChange={this.onChange} placeholder={this?.props?.placeholder} />    
            )
        }
        return (
            <input onChange={this.onChange} placeholder={this?.props?.placeholder} />
        )
    }

    render() {
        return this.renderInput()
    }
}

export const signupFields = {
    fields: [
        {
            type: 'text',
            name: 'name',
            validator: required,
            errorMessage: 'This field is required',
            placeholder: 'First Name Last Name*',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={validator}
                    />
                )
            },
        },
        {
            type: 'email',
            name: 'email',
            validator: email,
            errorMessage: 'Please enter a valid email address',
            placeholder: 'Email*',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={validator}
                    />
                )
            },
        },
        {
            type: 'text',
            name: 'phone',
            validator: phone,
            errorMessage: 'Please enter a valid phone number',
            placeholder: 'Phone Number*',
            component: ({ value, placeholder, onChangeValue, validator }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        validator={validator}
                    />
                )
            },
        },
        {
            type: 'textarea',
            name: 'notes',
            placeholder: 'Notes',
            component: ({ value, placeholder, onChangeValue, type }) => {
                return (
                    <Input
                        value={value}
                        placeholder={placeholder}
                        onChangeValue={onChangeValue}
                        type={type}
                    />
                )
            },
        },
    ],
}

export default ContactForm
