import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import NumberFormat from 'react-number-format'
import FullHeight from 'react-div-100vh'
import { isEqual } from 'lodash'
import { useMount, useUnmount } from 'react-use'
import { getCartTotal } from './utils'
import tw from 'tailwind.macro'

import { getGlobalData } from '../../utils'
import { media, width } from '../../styles/utils'
import { container, padding, type, bgImage, button } from '../../styles/global'
import { getNumberScaleProps, getPriceProps } from '../../utils/shopify'
import { cartContext } from './CartProvider'

const Cart = props => {
    const {
        cartItems,
        setCartItems,
        updateQuantity,
        checkout,
    } = useContext(cartContext)
    
    const { global: data } = getGlobalData()
    // console.log(data)
    // console.log(cartItems)

    const goToCheckout = () => {}

    const removeItemFromCart = item => {
        let updatedCartItems = []
        let removed = false

        cartItems.forEach((cartItem, index) => {
            if (isEqual(cartItem, item) && !removed) {
                removed = true
            } else {
                updatedCartItems.push(cartItem)
            }
        })

        setCartItems(updatedCartItems)
    }

    const renderSubtotals = () => {
        return (
            <Subtotals>
                <Total>
                    Total
                    <Amount>
                        <NumberFormat
                            value={getCartTotal(cartItems)}
                            prefix={'$'}
                            displayType={'text'}
                            {...getNumberScaleProps()}
                        />
                    </Amount>
                </Total>
            </Subtotals>
        )
    }

    const renderCart = () => {
        return (
            <>
                <Heading>Cart</Heading>
                {renderCartItems(
                    cartItems,
                    removeItemFromCart,
                    updateQuantity,
                )}
            </>
        )
    }

    return (
        <>
            <Wrapper id={'cart-wrapper'}>
                {renderCart()}

                <Footer>
                    {data.cart_message && (
                        <Notes
                            dangerouslySetInnerHTML={{
                                __html: data.cart_message,
                            }}
                        />
                    )}
                    <Nav>
                        {renderSubtotals()}
                        <Checkout
                            href={checkout?.webUrl}
                            target={'_blank'}
                            hasItems={
                                !checkout.processing &&
                                checkout?.webUrl &&
                                cartItems &&
                                cartItems.length > 0
                            }
                        >
                            {'Checkout'}
                        </Checkout>
                    </Nav>
                </Footer>
            </Wrapper>
        </>
    )
}

const renderSelectedOption = (option, i) => {
    if (option.name === 'Title' && option.value === 'Default Title') return
    return (
        <Subheading key={i}>
            <>
                {option.name == 'Title' && (
                    <span>{`${option.value}`}</span>
                )}
                {option.name != 'Title' && (
                    <span>{`${option.name}: ${option.value}`}</span>
                )}
            </>
        </Subheading>
    )
}

export const renderCartItems = (
    cartItems,
    removeItemFromCart,
    updateQuantity,
    renderSubtotals
) => {
    let items

    items = cartItems.map((item, i) => {
        let imageRewrite
        // if (item?.selectedVariant?.image?.originalSrc) {
        //     imageRewrite = item?.selectedVariant?.image?.originalSrc.replace('https://cdn.shopify.com/s','')
        // }

        const image = imageRewrite || item?.selectedVariant?.image?.originalSrc
        return (
            <CartItem key={i}>
                <Link to={`/shop/${item.handle}`}>
                    <BGImage image={image} />
                </Link>

                <Info>
                    <Heading>
                        {/* Title */}
                        <Link
                            to={`/shop/${item.handle}`}
                        >
                            {item.title}
                        </Link>
                    </Heading>

                    {/* Subtotals */}
                    <Subtotals>
                        <Total>
                            <NumberFormat
                                value={
                                    parseInt(
                                        item.selectedVariant.price
                                    ) * item.quantity
                                }
                                prefix={'$'}
                                displayType={'text'}
                                {...getNumberScaleProps()}
                            />
                        </Total>
                    </Subtotals>

                    {/* Selected Options */}
                    {item.selectedVariant.selectedOptions && (
                        <SelectedOptions>
                            {item.selectedVariant.selectedOptions.map(
                                (option, i) => (
                                    renderSelectedOption(option, i)
                                )
                            )}
                        </SelectedOptions>
                    )}

                    <Quantity>
                        <Label>Qty: </Label>
                        <NumberFormat
                            value={item.quantity}
                            decimalScale={0}
                            displayType={'text'}
                        />
                    </Quantity>

                    {/* Remove from Cart */}
                    <Remove
                        onClick={() => removeItemFromCart(item)}
                    >
                        Remove
                    </Remove>
                </Info>

                {/* Subtotals */}
                <Subtotals>
                    <Total>
                        <NumberFormat
                            value={
                                parseInt(
                                    item.selectedVariant.price
                                ) * item.quantity
                            }
                            prefix={'$'}
                            displayType={'text'}
                            {...getNumberScaleProps()}
                        />
                    </Total>
                </Subtotals>
            </CartItem>
        )
    })

    return (
        <CartItems>
            {cartItems.length > 0 && items}
            {!cartItems.length && (
                <Description>
                    There is nothing in your cart
                </Description>
            )}
        </CartItems>
    )
}

// Shared

const Icon = styled.img``
const Heading = styled.div``
const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
`

const Subheading = styled.div``
const Description = styled.div``

// Layout

const Wrapper = styled.div`
    ${tw`flex flex-col w-full h-full mx-auto`};
    max-width: 800px;

    > ${Heading} {
        ${type.heading}
    }

    ${Description} {
        ${tw`border-b border-grey`};
        padding: 2rem 0;
    }
`

const Label = styled.div``
const Total = styled.div``
const Amount = styled.div``
const Subtotals = styled.div`
    ${Total} {
        ${type.subheading}
    }
`

// Cart header

const Back = styled.div``
const Close = styled.div``

// Cart Items

const CartItems = styled.div`
    ${tw`relative w-full`};
    margin-top: 0.75rem;

    &::before {
        content: '';
        ${tw`absolute top-0 left-0 right-0 border-t border-grey`};
    }
`

// Cart Item

const Price = styled.div``
const Info = styled.div``
const SelectedOptions = styled.div``
const Remove = styled.div``
const Quantity = styled.div``
const CartItem = styled.div`
    ${tw`relative flex border-b border-grey`};
    padding: 1rem 0 2rem;

    ${BGImage} {
        height: 100%;
        width: 130px;
        margin-right: 1rem;
    }

    ${Info} {
        flex: 1;
        ${tw`flex flex-col justify-between`};

        * {
            user-select: none;
        }

        ${Heading} {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &,
            * {
                text-decoration: none;
                ${type.subheading}
            }
        }

        ${Subtotals} {
            display: none;

            ${media.mob`
                display: block;
                margin: 1rem 0;
            `}
        }

        ${SelectedOptions} {
            margin: 0.5rem 0;

            ${Subheading} {
                line-height: 1;
                &:not(:first-child) {
                    margin-top: 2px;
                }
            }
        }

        /* remove cart item */
        ${Remove} {
            cursor: pointer;
            text-decoration: underline;
        }

        ${Quantity} {
            ${tw`flex`};
        }
    }

    > ${Subtotals} {
        ${media.mob`
            display: none;
        `}
    }
`

// Cart footer

const Notes = styled.div``
const Nav = styled.div``

const Footer = styled.div`
    ${tw`flex justify-between`};
    margin-top: 2rem;

    ${media.mob`
        ${tw`flex-col`};
    `}

    ${Notes} {
        ${tw`w-1/2`};
        ${type.body}

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Nav} {
        ${tw`w-1/3 flex flex-col items-end`};

        ${media.mob`
            ${tw`w-full`};
            margin-top: 2rem;
        `}

        ${Subtotals} {
            ${tw`w-full`};

            ${Total} {
                ${tw`flex justify-between w-full`};
            }
        }
    }
`

// Checkout

const Checkout = styled.a`
    ${tw`w-full`};
    margin-top: 2rem;
    ${type.subheading}
    ${button}
    text-decoration: none;

    ${media.mob`
        margin-top: 4rem;
    `}

    pointer-events: ${props => (props.hasItems ? 'all' : 'none')};
    opacity: ${props => (props.hasItems ? 1 : 0.6)};
    cursor: ${props => (props.hasItems ? 'pointer' : 'default')};
`

export default Cart
